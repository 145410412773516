

@font-face {  font-family: 'Poppins-Black';   src: url('./font/Poppins-Black.ttf');     }
@font-face {  font-family: 'Poppins-Bold';   src: url('./font/Poppins-Bold.ttf');     }
@font-face {  font-family: 'Poppins-Light';   src: url('./font/Poppins-Light.ttf');     }
@font-face {  font-family: 'Poppins-Medium';   src: url('./font/Poppins-Medium.ttf');     }
@font-face {  font-family: 'Poppins-Regular';   src: url('./font/Poppins-Regular.ttf');     }

* {
  overflow-anchor: none;
}
:root{
    --primary-col: #00A896;
}

* {
    box-sizing: border-box;
    text-decoration: none;
    list-style: none;
}
 
body{
    font-family: Poppins-Regular !important;
    background-color: #FBFEFD !important;
}
a{ color: inherit !important; text-decoration: none !important;}

.no-margin{
    margin: 0px !important;
}
.no-padding{
    padding: 0px !important;
}

.h1 { font-size: clamp( 5em, 80vw, 10em)  !important;  }
.h2 { font-size: clamp( 4em, 80vw, 5em)  !important; }
.h3 { font-size: clamp( 3em, 80vw, 4em)  !important; }
.h4 { font-size: clamp( 2em, 50vw, 3em)  !important; }
.h5 { font-size: clamp( 1.2em, 1.8vw, 1.8em)  !important; }
.h6 { font-size: clamp( 1em, 1.5vw, 1.6em)  !important; }

.p0 { font-size: clamp( 16px, 1vw, 20px)  !important; }
.p1 { font-size: clamp( 14px, 1vw, 18px)  !important; }
.p2 { font-size: clamp( 12px, 1vw, 16px)  !important; }

.bold {font-weight: bold !important; }

.pryCol{color:var(--primary-col) !important}
.text-gray{ color: gray;}
.heightPadding{ padding-top: 4em;}
.side-padding{ padding-right: 2em; padding-left: 2em;}

.show-loading {
  display: inline-block;
  width: 25px;
  height: 25px;
  border: 3px solid var(--white);
  border-radius: 50%;
  border-top-color: #df557a;
  animation: spin 0.5s ease-in-out infinite;
  -webkit-animation: spin 0.5s ease-in-out infinite;
}

@keyframes spin {
  to { -webkit-transform: rotate(360deg); }
}
@-webkit-keyframes spin {
  to { -webkit-transform: rotate(360deg); }
}


/*********/

a:hover{ 
  color: #df557a !important;
}

.social-box{
  width: 50px; height: 50px; border-radius: 5px; margin: 1px 10px; cursor: pointer; background-color: #df557a;
  justify-content: center; align-items: center; display: flex;
}
.social-box:hover{ color: black !important;}

.order-button{
    border-radius: 5px; padding: 14px 20px; text-align: center;
    font-size: 18px; font-family: Poppins-Medium;
    /*border: 1px solid var(--primary-col) ;*/
    border: 1px solid #000000 ; background-color: black;
    min-height: 30px; min-width: 50px; color: #fff;
    cursor: pointer;
}
.button:hover{
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}
.title-bag{
  display:flex; justify-content:center; align-items:center; width:100%; height:100%;
  padding: 2em 1em;
}
.author-tag{font-size: 25px;}


.input {
  width: 100%;
  cursor: pointer;
  margin-bottom: 2px;
  border-radius: 5px;
  min-height: 20px;
}
.input:hover{
 border-width: 2px;
}

.p-divider::before{
  color: #df557a;
}