
/*Our mobile breaking point should be at 700px*/


@media (max-width: 700px) {
  .show-on-mobile {  display: block !important; }
  .hide-on-mobile {  display: none !important; }
  .full-width-on-mobile{width: 100% !important;}
  .no-padding-on-mobile{padding: 0px !important;}
  .app-header{padding: 0px 10px;}

   
  .align-left-mobile{align-items: flex-start !important;}

  .mobile-margin{margin-top: 2em;}

  .title-bag {
    height: auto;
  }
  .side-pad-mobile{
    padding-right: 1.2em !important; padding-left: 1.2em !important;
  }
  .side-pad-mobile2{
    padding-right: 2em !important; padding-left: 2em !important;
  }
  .nopaddingonmobile{
    padding-top: 0px !important;
  }
  

  ::-webkit-scrollbar{
    width: 0px;
    height: 1px;
  }

  .buttoned{
   width: 100% !important;
 }

 .h11, h1 { font-size: 22px;}
 .h22, h2 { font-size: 20px;}
 .h33, h3 { font-size: 18px;}
 .h44, h4 { font-size: 16px;}
 .h55, h5 { font-size: 14px;}
 .h66, h6 { font-size: 12px;}
 .h77 { font-size: 10px;}

}



/*lg breaking point*/
@media(max-width:992px){
   
.card-1{margin-top:1.7em;}
.desktop-menu-list { font-size: 1.0em}
.bx-stat-box{  width: 48%;}  
.bx-bx1{font-size: 1.1em; margin-bottom: -2px;}
.bx-bx{font-size: 0.68em;}

}



/*md breaking point*/
@media(max-width:768px){
  .break-no-padding{padding: 0px !important;}
  .mg-top{margin-top: 1.2em;}
  .gpi-title-col {
     margin-top: 0.6em; } 
  .g-title{
    width: 49%; display: inline-block;
  }  
  .g-value{
    width: 49%; display: inline-block; margin-left: 0px;
  }
  .chart-pole{  min-width: 1.3em;  }  
  .comp-add-state{width:100%; margin-left: 0px;}
.comp-text-hd{display: block;}
.comp-flex-box{ 
  display: block;
}
.card-1{
  margin-top:2em; 
}
.right-hd{width: 50%;}
.left-hd{width: 48%;}

h1{  font-size: 2.4em; font-weight: bold;
}
h2{  font-size: 2em; font-weight: bold;
}
h3{  font-size: 1.5em; font-weight: bold;
}
h4{  font-size: 1.3em; font-weight: bold;
}
h5{
  font-size: 1.15em; font-weight: bold;
}
h6{
  font-size: 0.9em; font-weight: bold;
}
.tab-align{
  width: 49%;
}
.tab-align-last-odd{
  width: 99%;
}




}



/*sm breaking point*/
 @media(max-width:576px){
  .side-margin{
    margin: 0px !important;
  }
  .db-bag-hd{font-size: 1em; padding-top: 15px;}
  .db-button{font-size: 0.8em;}
   .search{display: none;}
   .activate-search{display: block;}
   .right-hd{width: 30%;}
   .left-hd{width: 68%;}
  .tab-align-last-odd{
   width: 98%;
 }
 .db-sm-n{padding: 0px !important;}
 .close-box{line-height: 1.4em; padding: 10px 10px;}
 .tab-align{
   width: 48%;
 }
 .body-tab{
   padding-left: 1em;
   padding-right: 1em;
 }
.comp-flex-box{ display: block;}
.chart-box {  margin: 1px 0.1em;}
.chart-pole{  min-width: 1em;  }
 .main-padding{
   padding: 1.3em !important;
 }
 .surveyLinkImg{height: 240px;}

.staff-nav-box{
  width: 2em;
  height: 2em;
  border-radius: 1em;
}
.owl-box{
  width: 70% !important;
}




}


@media(max-width:350px){
   
 .tab-align{
   width: 100%;
 }
}






 @keyframes mobilehide{
  from{
      opacity: 1;
    
  }
  to {
      opacity: 0;
  }
}

@keyframes mobileshow{
  from{
      opacity: 0;
  }
  to {
      opacity: 1;
  }
}